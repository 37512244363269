import '../css/app.css'
import './bootstrap'
import '../../node_modules/nprogress/nprogress.css' // NProgress

import { createPinia } from 'pinia'
import { createApp } from 'vue'
import { createI18n } from 'vue-i18n'
import NProgress from 'nprogress';
//@ts-ignore
import { authPlugin } from 'vue3-auth'
import { Lang } from './Lang/Lang'
import router from './routes'
import auth from './auth'
import VueSocialSharing from 'vue-social-sharing'
import App from './App.vue'
import { createHead } from '@unhead/vue'
import * as Sentry from "@sentry/vue";

import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'

const locale = localStorage.getItem('lang') || 'id'

router.beforeResolve((to, from, next) => {
    // If this isn't an initial page load.
    if (to.name) {
        // Start the route progress bar.
        NProgress.start()
    }

    next()
})

router.afterEach(() => {
    NProgress.done()
})

const i18n = createI18n({
    locale: locale, // set locale
    fallbackLocale: 'en', // set fallback locale
    messages: Lang,
    useScope: 'global',
    legacy: false,
    globalInjection: true
})

const pinia = createPinia()
const app = createApp(App)
const head = createHead()

Sentry.init({
    app: app,
    dsn: "https://ace0f1103a0095d9f06a85cae6b12e16@o4506604733267968.ingest.sentry.io/4506604734775296",
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost"],
      }),
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

app.use(head)
app.use(pinia)
app.use(router)
app.use(i18n)
app.use(authPlugin, auth)
app.use(VueSocialSharing)
app.use(VueViewer)
app.mount('#app')

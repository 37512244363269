<template>
    <div class="container py-12">
        <LoadItemText class="text-2xl font-bold mb-6">
            Load Shorts
        </LoadItemText>
        <swiper-container ref="swiperEl" init="false" class="relative lg:h-[500px]">
            <swiper-slide v-for="item in 10">
                <div class="w-full h-full aspect-[9/16] content-[''] block bg-slate-100">
                    <LoadContainer class="w-full h-full" />
                </div>
            </swiper-slide>
        </swiper-container>
    </div>
</template>

<script setup lang="ts">
import LoadItemText from './LoadItemText.vue';
import LoadContainer from './LoadContainer.vue';
import { SwiperContainer, register } from 'swiper/element/bundle';
import { SwiperOptions } from 'swiper/types';
import { onMounted, ref } from "vue";

register();

const swiperEl = ref<SwiperContainer>();

onMounted(() => {

    const params: SwiperOptions | null = {
        navigation: {
            nextEl: '.next-short',
            prevEl: '.prev-short',
        },
        pagination: {
            dynamicBullets: true,
        },
        grabCursor: true,
        slidesPerView: 1,
        breakpoints: {
            768: {
                slidesPerView: 2,
                spaceBetween: 10,
            },
            840: {
                slidesPerView: 3,
                spaceBetween: 10,
            },
            1024: {
                slidesPerView: 4,
                spaceBetween: 10,
            },
            1280: {
                slidesPerView: 5,
                spaceBetween: 10,
            },
        },
        spaceBetween: 10,
        injectStyles: [`
            .swiper-wrapper { align-items:center; }
            .swiper-pagination { margin: 0 0 10px; }
            .swiper-pagination-bullet-active { background: #B370EF; }
        `]
    }


    Object.assign(swiperEl.value as SwiperContainer, params)
    swiperEl.value?.initialize()
});
</script>
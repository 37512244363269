<template>
    <section class="bg-slate-50">
        <div class="container pb-16">
            <div class="flex flex-row gap-6">
                <LoadItemText class="text-2xl mb-6 font-bold">
                    Loading Collection </LoadItemText>
                <LoadItem class="h-8 w-48 rounded-md" />
            </div>
            <div class="sm:grid sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 sm:gap-3 relative py-8 sm:pl-12">
                <div
                    class="relative z-10 h-full flex flex-col justify-center items-center sm:items-start gap-4 sm:gap-8 max-sm:py-6">

                </div>
                <div
                    class="sm:w-1/2 md:w-1/3 lg:w-1/4 w-full max-sm:h-1/2 sm:h-full rounded-xl content-[''] absolute top-0 left-0 block">
                    <LoadContainer class="h-full" />
                </div>

                <div class="col-span-2 md:col-span-3 lg:col-span-5 max-sm:px-4">
                    <swiper-container ref="swiperCategories" init="false">
                        <swiper-slide v-for="product in 10" class="px-1 py-2">
                            <LoadProduct />
                        </swiper-slide>
                    </swiper-container>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup lang="ts">
import LoadContainer from './LoadContainer.vue';
import LoadProduct from './LoadProduct.vue';
import LoadItemText from './LoadItemText.vue';
import LoadItem from './LoadItem.vue';
import { SwiperContainer, register } from 'swiper/element/bundle';
import { SwiperOptions } from 'swiper/types';
import { onMounted, ref } from "vue";

register();

const swiperCategories = ref<SwiperContainer>();


onMounted(() => {
    const params: SwiperOptions | null = {
        autoplay: {
            delay: 2000,
        },
        navigation: {
            nextEl: '.next-category',
            prevEl: '.prev-category',
        },
        spaceBetween: 0,
        slidesPerView: 1,
        breakpoints: {
            480: {
                slidesPerView: 2,
                spaceBetween: 5,
            },
            840: {
                slidesPerView: 3,
                spaceBetween: 5,
            },
            1024: {
                slidesPerView: 4,
                spaceBetween: 5,
            },
            1280: {
                slidesPerView: 5,
                spaceBetween: 5,
            },
        },
    }

    Object.assign(swiperCategories.value as SwiperContainer, params)
    swiperCategories.value?.initialize();
});

</script>
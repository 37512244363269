import { useToast } from '@/Components/ui/toast/use-toast'
import { defineStore } from 'pinia'
import Swal from 'sweetalert2'
import { computed, ref } from 'vue'
import { useLocales } from './Locale'
import { useShipment } from './Shipment'
type variant = {
    variant1Id?: number
    variant2Id?: number
}

export const useCart = defineStore('useCart', () => {
    const { toast } = useToast()
    // state = reactive
    const cart_data = ref<any>()
    const taxed_product = ref<any>()
    const assurance_product = ref<any>()
    const coupon_code = ref<any>()
    const used_code = ref<any>(null)
    const vourcher_response = ref<any>()
    const discount = ref(0)
    const haveAddress = ref(false)
    const paymentMethod = ref('')
    const cost_shipping = ref(0)
    const paypal = ref(0)
    const taxGlobal = ref(0)
    const taxLocal = ref(0)
    const packingFeeGlobal = ref(0)
    const packingFeeLocal = ref(0)
    const paymentLimit = ref(0)
    const ratesCurrency = ref<any>(null)

    // Asuransi
    const localInsurance = ref(0)
    const globalInsurance = ref(0)
    const minLocalInsurance = ref(0)
    const minGlobalInsurance = ref(0)
    const enableInsurance = ref(false)

    // getter = computed
    const totalQuantity = computed(() => {
        return cart_data.value?.reduce((prev: any, curr: any) => {
            return prev + curr.quantity
        }, 0)
    })

    const totalWeight = computed(() => {
        return cart_data.value?.reduce((prev: any, curr: any) => {
            return Math.round(prev + curr.weight * curr.quantity)
        }, 0)
    })

    const totalPrice = computed(() => {
        return cart_data.value?.reduce((prev: any, curr: any) => {
            const itemPrice = curr.discount ? curr.discount : curr.price
            return prev + itemPrice * curr.quantity
        }, 0)
    })

    // Find is_out_of_stock from cart_data
    const isOutOfStock = computed(() => {
        return (
            cart_data.value?.filter((item: any) => item.is_out_of_stock == true)
                .length > 0
        )
    })

    const shippingCost = computed(() => {
        const weight = totalWeight.value
        if (useShipment().selected_address?.country != 'Indonesia') {
            // Light Item (White Envelope)
            if (weight < 200) {
                if (useLocales().isLang == 'id') {
                    return 20 * Number(ratesCurrency.value?.rate)
                } else {
                    return 20
                }
                // Medium Item (Brown Envelope)
            } else if (weight > 200 && weight <= 2000) {
                let temp = 55

                if (weight > 1000) {
                    temp += 10
                }

                if (weight > 1300) {
                    temp += 10
                }

                if (weight > 1600) {
                    temp += 10
                }

                if (useLocales().isLang == 'id') {
                    return temp * Number(ratesCurrency.value?.rate)
                } else {
                    return temp
                }
            } else {
                let totalBox = Math.ceil(weight / 4000)
                let baseShip = 150

                if (useLocales().isLang == 'id') {
                    return (
                        baseShip * totalBox * Number(ratesCurrency.value?.rate)
                    )
                } else {
                    return baseShip * totalBox
                }
            }
        } else {
            return Number(cost_shipping.value)
        }
    })

    const totalTaxedProduct = computed(() => {
        return taxed_product.value?.reduce((prev: any, curr: any) => {
            const itemPrice = curr.discount ? curr.discount : curr.price
            return prev + itemPrice * curr.quantity
        }, 0)
    })

    const totalAssuranceProduct = computed(() => {
        let totalAssurance = 0
        let temp = 0

        if (useShipment().selected_address?.country === 'Indonesia') {
            let minLocal = 0

            // Case Jika Pake Bahasa Inggris
            if (useLocales().isLang != 'id') {
                temp = totalPrice.value * Number(localInsurance.value)
                minLocal = Number(minLocalInsurance.value) * Number(ratesCurrency.value?.rate)
                // Case Jika Pake Bahasa Indonesia
            } else {
                temp = totalPrice.value * Number(localInsurance.value)
                minLocal = Number(minLocalInsurance.value)
            }

            console.log('temp', temp)
            console.log('minLocal', minLocal)

            if (temp < minLocal) {
                totalAssurance = minLocal
            } else {
                totalAssurance = temp
            }
            // Case Jika Negara Luar Indonesia
        } else {
            let minGlobal = 0
            // Case Jika Pake Bahasa Indonesia
            if (useLocales().isLang == 'id') {
                temp = totalPrice.value * Number(globalInsurance.value)
                minGlobal = Number(minGlobalInsurance.value) * Number(ratesCurrency.value?.rate)
                // Case Jika Pake Bahasa Inggris
            } else {
                temp = totalPrice.value * Number(globalInsurance.value)
                minGlobal = Number(minGlobalInsurance.value)
            }

            if (temp < minGlobal) {
                totalAssurance = minGlobal
            } else {
                totalAssurance = temp
            }
        }

        return totalAssurance
    })
    // const totalAssuranceProduct = computed(() => {
    //     if (!assurance_product.value) {
    //         return 0
    //     }

    //     let totalAssurance = 0
    //     let temp = 0

    //     for (const curr of assurance_product.value) {
    //         const itemPrice = curr.discount ? curr.discount : curr.price
    //         // Case Jika Negara Indonesia
    //         if (useShipment().selected_address?.country === 'Indonesia') {
    //             let minLocal = 0
    //             // Case Jika Pake Bahasa Inggris
    //             if (useLocales().isLang != 'id') {
    //                 minLocal =
    //                     Number(minLocalInsurance.value) *
    //                     Number(ratesCurrency.value?.rate)
    //                 // Case Jika Pake Bahasa Indonesia
    //             } else {
    //                 minLocal = Number(minLocalInsurance.value)
    //             }

    //             temp = itemPrice * Number(localInsurance.value) * curr.quantity

    //             if (temp < minLocal) {
    //                 totalAssurance += minLocal
    //             } else {
    //                 totalAssurance += temp
    //             }
    //             // Case Jika Negara Luar Indonesia
    //         } else {
    //             let minGlobal = 0
    //             // Case Jika Pake Bahasa Indonesia
    //             if (useLocales().isLang == 'id') {
    //                 minGlobal =
    //                     Number(minGlobalInsurance.value) *
    //                     Number(ratesCurrency.value?.rate)
    //                 // Case Jika Pake Bahasa Inggris
    //             } else {
    //                 minGlobal = Number(minGlobalInsurance.value)
    //             }

    //             temp = itemPrice * Number(globalInsurance.value) * curr.quantity

    //             if (temp < minGlobal) {
    //                 totalAssurance += minGlobal
    //             } else {
    //                 totalAssurance += temp
    //             }
    //         }
    //     }

    //     return totalAssurance
    // })

    const cartSummary = computed(() => {
        let subtotalPrice = ref(0)
        let convertPackingFeeLocal = packingFeeLocal.value
        let convertPackingFeeGlobal = packingFeeGlobal.value

        if (useShipment().selected_address?.country == 'Indonesia') {
            if (useLocales().isLang == 'id') {
                subtotalPrice.value =
                    totalPrice.value +
                    shippingCost.value +
                    packingFeeLocal.value
            } else {
                const IdrRate = Number(ratesCurrency.value?.rate)
                convertPackingFeeLocal = Number(
                    Number(packingFeeLocal.value) * Number(IdrRate)
                )
                console.log('convertShipping', convertPackingFeeLocal)
                subtotalPrice.value =
                    totalPrice.value +
                    shippingCost.value +
                    convertPackingFeeLocal
            }
        } else {
            if (useLocales().isLang == 'id') {
                const Rate = Number(ratesCurrency.value?.rate)
                convertPackingFeeGlobal = Number(
                    Number(packingFeeGlobal.value) * Number(Rate)
                )
                subtotalPrice.value =
                    totalPrice.value +
                    shippingCost.value +
                    convertPackingFeeGlobal
            } else {
                subtotalPrice.value =
                    totalPrice.value +
                    shippingCost.value +
                    packingFeeGlobal.value
            }
        }

        if (enableInsurance.value == true) {
            subtotalPrice.value += totalAssuranceProduct.value
        } else {
            subtotalPrice.value = subtotalPrice.value
        }

        if (
            subtotalPrice.value >
            vourcher_response.value?.voucher_minimum_payment
        ) {
            if (vourcher_response.value?.voucher_type == 'percentage') {
                discount.value =
                    (subtotalPrice.value *
                        vourcher_response.value.voucher_value) /
                    100

                if (
                    discount.value >
                    vourcher_response.value.voucher_maximum_discount
                ) {
                    discount.value =
                        vourcher_response.value.voucher_maximum_discount
                }
            } else if (vourcher_response.value?.voucher_type == 'fixed') {
                discount.value = vourcher_response.value.voucher_value
            }
        } else {
            discount.value = 0
        }

        const taxFee = ref(0)

        if (useShipment().selected_address?.country != 'Indonesia') {
            taxFee.value = totalTaxedProduct.value * taxGlobal.value
        } else {
            taxFee.value = totalTaxedProduct.value * taxLocal.value
        }

        if (subtotalPrice.value > discount.value) {
            subtotalPrice.value -= discount.value
            subtotalPrice.value += taxFee.value
        } else {
            // Konteks jika discount lebih besar dari subtotal
            // Maka discount max akan diforce menjadi tidak lebih dari subtotal
            // dan untuk finalnya diskon dikurang dengan subtotal
            // proses keduanya diperlukan guna untuk tampilan front end
            discount.value = subtotalPrice.value
            subtotalPrice.value -= discount.value
            taxFee.value = taxFee.value
            subtotalPrice.value += taxFee.value
        }

        const grandTotal = ref(0)
        const paypalFee = ref(0)

        if (haveAddress.value) {
            if (
                paymentMethod.value == 'paypal' ||
                paymentMethod.value == 'wise'
            ) {
                paypalFee.value = subtotalPrice.value * paypal.value
                grandTotal.value = subtotalPrice.value + paypalFee.value
            } else {
                grandTotal.value = subtotalPrice.value
            }
        }

        return {
            totalQuantity: totalQuantity.value,
            totalWeight: totalWeight.value,
            shippingCost: shippingCost.value,
            totalShopping: totalPrice.value,
            discount: discount.value,
            taxGlobal: taxGlobal.value,
            taxLocal: taxLocal.value,
            taxFee: taxFee.value,
            packingFeeGlobal: convertPackingFeeGlobal,
            packingFeeLocal: convertPackingFeeLocal,
            totalAssuranceProduct: totalAssuranceProduct.value,
            paypal: paypal.value,
            paypalfee: paypalFee.value,
            subtotalPrice: subtotalPrice.value,
            grandTotal: grandTotal.value
        }
    })

    // action = method
    const getRatesCurrency = async (lang: string) => {
        try {
            if (lang == 'id') {
                const { data: ratesResponse } = await window.axios.get(
                    `api/currency/rate?base=IDR&target=USD`
                )
                ratesCurrency.value = ratesResponse.data
            } else {
                const { data: ratesResponse } = await window.axios.get(
                    `api/currency/rate?base=USD&target=IDR`
                )
                ratesCurrency.value = ratesResponse.data
            }
        } catch (error) {
            console.error(error)
        }
    }

    const getSetting = async () => {
        try {
            const { data: settingResponse } =
                await window.axios.get(`api/order/setting`)

            paypal.value = settingResponse.data.paypal_fee / 100
            taxGlobal.value = settingResponse.data.global_shipping_tax / 100
            taxLocal.value = settingResponse.data.local_shipping_tax / 100
            packingFeeGlobal.value = Number(
                settingResponse.data.global_packing_fee
            )
            packingFeeLocal.value = Number(
                settingResponse.data.local_packing_fee
            )
            paymentLimit.value = settingResponse.data.payment_duration

            // Asuransi
            localInsurance.value = settingResponse.data.local_assurance / 100
            globalInsurance.value = settingResponse.data.global_assurance / 100
            minLocalInsurance.value =
                settingResponse.data.assurance_minimum_local
            minGlobalInsurance.value =
                settingResponse.data.assurance_minimum_global
        } catch (error) {
            console.error(error)
        }
    }

    const addToCart = async (
        user_id: number,
        productId: number,
        variant: variant,
        qty: number,
        note: string,
        direct: boolean
    ) => {
        // console.log(user_id, productId, variant, qty, note);
        const data = {
            ...variant,
            user_id,
            productId,
            quantity: qty,
            note
        }

        await window.axios
            .post('api/cart?local', data)
            .then((response) => {
                toast({
                    title: 'Product Added!!'
                })
                getCart()

                if (direct) {
                    window.location.href = '/cart'
                }
            })
            .catch((error) => {
                console.error(error)
                Swal.fire({
                    icon: 'error',
                    title: error.response.data.message,
                    text: 'You cant add more stock than available',
                    showConfirmButton: false,
                    timer: 2000
                })
            })
    }

    // updateCart
    const updateCart = async (id: number, qty: number) => {
        const data = {
            quantity: qty
        }
        try {
            await window.axios.post(`api/cart/${id}`, data)
            getCart()
            checkCoupon()
        } catch (error) {
            console.error(error)
        }
    }

    const getCart = async () => {
        try {
            const { data: cartResponse } = await window.axios.get(
                `api/cart?local=${useLocales().isLang}`
            )
            cart_data.value = cartResponse.data

            const filteredTax = cart_data.value.filter(
                (item: any) => !item.is_tax_included
            )
            taxed_product.value = filteredTax

            const filteredAssurance = cart_data.value.filter(
                (item: any) => item.is_required_assurance
            )
            assurance_product.value = filteredAssurance
        } catch (error) {
            console.error(error)
        }
    }

    const removeCart = (id: number) => {
        Swal.fire({
            icon: 'question',
            title: 'Removed Product From Cart?',
            showCancelButton: true,
            confirmButtonText: 'Remove'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await window.axios.delete(`api/cart/${id}`)
                    getCart()
                    Swal.fire({
                        icon: 'success',
                        title: 'Product Removed',
                        showConfirmButton: false,
                        timer: 1500
                    })
                } catch (error) {
                    console.error(error)
                }
            }
        })
    }

    const checkCoupon = async () => {
        // console.log('coupon_code', coupon_code.value)
        if (coupon_code.value?.length >= 5) {
            const data = {
                code: coupon_code.value
            }
            try {
                const { data: voucherResponse } = await window.axios.post(
                    `api/voucher/check?local=${useLocales().isLang}`,
                    data
                )
                console.log('voucherResponse', voucherResponse)
                vourcher_response.value = voucherResponse.data
                used_code.value = coupon_code.value
            } catch (error: any) {
                console.error(error)
                // vourcher_response.value = null;
                discount.value = 0
                Swal.fire({
                    icon: 'error',
                    title: error.response.data.message
                })
                used_code.value = null
            }
        }
    }

    return {
        // state
        cart_data,
        taxed_product,
        assurance_product,
        coupon_code,
        haveAddress,
        paymentMethod,
        vourcher_response,
        cost_shipping,
        isOutOfStock,
        ratesCurrency,
        used_code,
        localInsurance,
        globalInsurance,
        minLocalInsurance,
        minGlobalInsurance,
        enableInsurance,

        // getter
        totalAssuranceProduct,
        cartSummary,
        totalQuantity,
        totalTaxedProduct,
        totalWeight,
        // action
        addToCart,
        updateCart,
        getCart,
        removeCart,
        checkCoupon,
        getSetting,
        getRatesCurrency
    }
})

<template>
    <template v-if="!isTyping && !isFetching">
        <div v-for="item in List" :key="item.id" @click="redirectTo(item.product_name)"
            class="flex flex-row items-center cursor-pointer justify-left gap-3 px-3 py-2 hover:bg-slate-100">
            <Search :size="20" class="text-slate-300" /> {{ item.product_name }}
        </div>
    </template>
    <div v-if="isTyping && isFetching" class="space-y-2 p-2">
        <Skeleton class="w-full h-8" />
        <Skeleton class="w-full h-8" />
        <Skeleton class="w-full h-8" />
    </div>
</template>

<script setup lang="ts">
import { Search } from "lucide-vue-next";
import { ref, onBeforeUnmount } from 'vue';
import { useRouter } from 'vue-router';

import Skeleton from "../ui/skeleton/Skeleton.vue";
import { useNavbarSearch } from '@/Store/NavbarSearch';
import { storeToRefs } from 'pinia';

const store = useNavbarSearch();
const { isTyping, isFetching, List } = storeToRefs(store);
const router = useRouter();

const redirectTo = (search: string) => {
    console.log(search)
    store.addToHistory(search);

    // redirect to search page
    router.push({
        name: 'Search',
        query: {
            q: search,
        }
    });
}

onBeforeUnmount(() => {
    store.clearList();
})
</script>

<template>
    <div
        class="hidden w-full transition-all min-h-[4px] duration-500 relative bg-gradient-to-l from-theme1 to-theme2 content-[''] sm:flex">

        <div v-show="route.name != 'Product' && route.name != 'Cart' && route.name != 'Shipment' && route.name != 'Payment' && route.name != 'Status'"
            class="px-4 w-full flex flex-row items-center justify-between animate__animated animate__fadeIn">

            <div class="grow flex flex-row items-center justify-start">
                <!-- <span class="text-white capitalize font-normal text-sm">categories:</span> -->
                <component :is="item.children.length == 0 ? 'router-link': 'div' "
                    :to="item.children.length == 0 ? { name: 'Search', query: { categories: item.slug } } : null"
                    v-for="item in category_data" :class="{ 'pr-3': item.children.length > 0 }"
                    class="group/menu bg-black bg-opacity-0 transition-all hover:bg-opacity-10 text-white text-sm font-normal flex flex-row items-center justify-center gap-0.5 px-3 py-2 relative cursor-pointer group/category">
                    {{ item.name }}
                    <ChevronDown class="group-hover/menu:rotate-180 duration-500 transition-all"
                        v-if="item.children.length > 0" :size="16" />
                    <div v-if="item.children.length > 0"
                        class="absolute left-0 z-10 min-w-[140px] max-w-[160px] group-hover/category:translate-y-1 group-hover/category:border rounded-md shadow-lg top-full bg-white text-black flex flex-col max-h-0 group-hover/category:max-h-[200px] overflow-x-hidden overflow-y-auto scrollbar-thin scrollbar-track-slate-50 scrollbar-thumb-slate-200 transition-all duration-500">
                        <router-link :to="{ name: 'Search', query: { categories: child.slug } }"
                            v-for="child in item.children" class="px-4 py-2 hover:bg-slate-100">{{ child.name
                            }}</router-link>
                    </div>
                </component>
            </div>
            <div class="hidden shrink-0 md:flex flex-row gap-3 text-white text-xs text-normal">
                <a href="https://www.tokopedia.com/xiaokenshop" target="_blank"
                    class="flex hover:opacity-75 transition-all flex-row gap-1.5 items-center justify-center">
                    <Store :size="16" :stroke-width="2" />Tokopedia
                </a>
                <a href="https://xiaokenshop.booth.pm/" target="_blank"
                    class="flex hover:opacity-75 transition-all flex-row gap-1.5 items-center justify-center">
                    <Store :size="16" :stroke-width="2" />Booth
                </a>
                <a href="https://maps.app.goo.gl/rdtDVndk4sq5EZ4e9" target="_blank"
                    class="flex hover:opacity-75 transition-all flex-row gap-1.5 items-center justify-center">
                    <Store :size="16" :stroke-width="2" />Offline Store
                </a>
                <div>

                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { useLocales } from "@/Store/Locale";
import { ChevronDown, Store } from "lucide-vue-next";
import { storeToRefs } from "pinia";
import { ref } from "vue";

// import vueroute
import { useRoute } from "vue-router";
const route = useRoute();

// console.log(route.name == 'Home');

const { isLang } = storeToRefs(useLocales());
const category_data = ref<any>(null);

const getMainCategory = async () => {
    try {
        const response = await window.axios.get(`api/categories?lang=${isLang.value}&show_main=true`);
        const data = response.data;
        category_data.value = data;
    } catch (error) {
        console.error(error);
    }
};

await getMainCategory();
</script>

<style scoped></style>
